// parts/LanguageSwitcher.js

import React from "react";
import { useTranslation } from "react-i18next";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const selectStyle = {
    backgroundColor: "#FFFFFF", // 白色背景
    color: "#236293", // 黑色文字
    cursor: "pointer", // 鼠标手势
    outline: "none", // 去除焦点轮廓
    border: "none", // 无边框
    borderRadius: "20px", // 圆角边框
    width: "100%", // 宽度填满父元素
    boxSizing: "border-box", // 盒子模型设定
    margin: "0 auto", // 自动外边距使其居中
    fontWeight: "400",
    // fontSize: "16px",
  };

  return (
    <select
      onChange={changeLanguage}
      defaultValue={i18n.language}
      style={selectStyle} // 应用内联样式
    >
      <option value="en">English</option>
      <option value="zh">简体中文</option>
      <option value="zh-TW">繁体中文</option>
    </select>
  );
}

export default LanguageSwitcher;
