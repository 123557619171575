/* eslint-disable react/jsx-filename-extension */
import React from "react";
import Button from "elements/Button";
// import { ReactComponent as Logo } from "../assets/images/OnDotLogo.svg";

export default function BrandIcon() {
  return (
    <Button className="flex items-center space-x-2" type="link" href="/">
      {/* <Logo className="w-8 h-8" /> */}
      <div>
        <p className="text-theme-blue text-4xl">
          OnDot
          <span className="text-theme-ondot">.AI</span>
        </p>
      </div>
    </Button>
  );
}
